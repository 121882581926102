<template>
    <div class="page-org-settings">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="config" ref="config">
                        <app-loader v-if="loading.features || processing" fixed-on="desktop"></app-loader>

                        <div class="board settings">

                            <h5>Settings</h5>

                            <app-error :value="error_message"></app-error>

                            <div class="info name">
                                <app-input :value="provider.Name"        label="Name"                       readonly></app-input>
                                <app-input :value="provider.SPID"        label="Service Provider ID (SPID)" readonly></app-input>
                            </div>

                            <div class="info name">
                                <app-input v-model="provider.DefaultSupportPhoneNumber" label="Support Phone Number" readonly></app-input>
                                <app-input v-model="provider.TaxNumber"                 label="Tax Number"           readonly></app-input>
                            </div>

                            <h5>Notifications Settings</h5>

                            <div class="info notifications">
                                <app-input :value="provider.DefaultEmailFromName"    label="Default Email from Name"    readonly></app-input>
                                <app-input :value="provider.DefaultEmailFromAddress" label="Default Email from Address" readonly></app-input>
                            </div>

                            <div class="info notifications">
                                <app-input :value="provider.DefaultSMSFromName"      label="Default SMS from Name"      readonly></app-input>
                                <app-input :value="provider.DefaultSMSFromNumber"    label="Default SMS from Number"    readonly></app-input>
                            </div>

                            <h5>Logos</h5>

                            <div class="info logo">
                                <app-input :value="provider.LogoWideURL" label="Wide Logo URL"       readonly></app-input>

                                <div class="logo-preview" v-if="loaded_images.LogoWideURL && !errors.logos.LogoWideURL">
                                    <img class="wide" :src="provider.LogoWideURL">
                                </div>
                            </div>

                            <div class="info logo">
                                <app-input :value="provider.LogoIconURL" label="Small/Icon Logo URL" readonly></app-input>

                                <div class="logo-preview" v-if="loaded_images.LogoIconURL && !errors.logos.LogoIconURL">
                                    <img class="small" :src="provider.LogoIconURL">
                                </div>
                            </div>
                        </div>

                        <div class="board features">
                            <app-checkbox-group v-model="provider.Features" label="Features"
                                :options="modules_sp"
                                
                                key-value="Name"
                                key-title="Title"

                                readonly
                            ></app-checkbox-group>
                        </div>

                        <div class="board branding">
                            <h5>Branding preferences</h5>

                            <div class="branding-body" :class="{ loading: loading.branding }">
                                <app-loader                 v-if="loading.branding"></app-loader>
                                <app-error             v-else-if="errors.branding" v-model="errors.branding"></app-error>
                                <app-provider-branding v-else-if="with_branding"   v-model="provider_branding" :original="original_branding" :disabled="processing"></app-provider-branding>
                            </div>
                        </div>

                        <button class="btn btn-primary btn-small" @click="save" :disabled="!is_changed_branding || processing">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appCheckboxGroup from '@/components/app-checkbox-group'

import appProviderBranding from '@/components/app-provider-branding'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,

        appInput,
        appCheckboxGroup,

        appProviderBranding,
    },

    data() {
        return {
            provider_branding: {},
            original_branding: {},

            errors: {
                branding: null,

                logos: {},
            },

            loading: {
                features: false,
                branding: false,
            },
            processing: false,

            loaded_images: {},
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadProvidersInfo()
        },

        loadProvidersInfo() {
            this.loadProvidersFeatures()
            this.loadBranding()

            this.loaded_images = {}

            this.checkLogoAvailability('LogoWideURL')
            this.checkLogoAvailability('LogoIconURL')
        },

        checkLogoAvailability(key) {
            const sizes = {
                LogoWideURL: 'wide',
                LogoIconURL: 'small'
            }

            const img = new Image()

            img.onload = () => {
                this.errors.logos = {
                    ...this.errors.logos,
                    [key]: null,
                }

                this.loaded_images = {
                    ...this.loaded_images,
                    [key]: true,
                }
            }

            img.onerror = () => {
                this.errors.logos = {
                    ...this.errors.logos,
                    [key]: `Something went wrong with the ${ sizes[key] } logo loading`,
                }

                this.loaded_images = {
                    ...this.loaded_images,
                    [key]: true,
                }
            }

            img.src = this.provider[key]
        },

        loadProvidersFeatures() {
            this.loading.features = true

            const stopLoading = () => { this.loading.features = false }

            this.$store.dispatch('getProvidersFeatures').then(stopLoading).catch(stopLoading)
        },

        loadBranding() {
            this.loading.branding = true
            
            this.$store.dispatch('getBrandingBySPID', { spid: this.provider.SPID }).then(branding => {
                this.initBranding(branding)

                this.loading.branding = false
            }).catch(error => {
                this.errors.branding = errMessage(error)

                this.loading.branding = false
            })
        },
        initBranding(branding) {
            let provider_branding = {}
            let original_branding = {}

            for (const key in branding) {
                provider_branding[ key ] = [...branding[ key ]]
                original_branding[ key ] = [...branding[ key ]]
            }

            this.provider_branding = provider_branding
            this.original_branding = original_branding
        },

        save() {
            if (this.is_changed_branding) {
                this.saveBranding().catch(() => { window.scrollTo(0, this.$refs.config.offsetTop) })
            }
        },

        saveBranding() {
            this.processing = true

            return this.$store.dispatch('saveBranding', { spid: this.provider.SPID, scheme: this.provider_branding }).then(branding => {
                this.initBranding(branding)
                
                this.$emit('apply-branding', branding)

                this.processing = false
            }).catch(error => {
                this.errors.settings = errMessage(error)

                this.processing = false

                return Promise.reject(error)
            })
        },

        isArrayChanged(value, original, is_color) {
            let is_changed = false

            const length = value.length

            if (length == original.length) {
                if (is_color) {
                    for (let i = 0; i < length; i++) {
                        if (this.hexColor(value[i]) != this.hexColor(original[i])) {
                            is_changed = true
                            break
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        if (original.indexOf( value[i] ) < 0) {
                            is_changed = true
                            break
                        }
                    }
                }
            } else {
                is_changed = true
            }

            return is_changed
        },

        hexColor(color) {
            let hex = color ? color.replace('#', '').trim().toLowerCase() : '00000000'

            if (hex.length == 3) {
                let hex_6 = ''

                for (let i = 0; i < 3; i++) {
                    hex_6+= hex[i] + hex[i]
                }

                hex = hex_6
            }

            return hex
        },
    },

    watch: {
        current_spid() {
            this.loadProvidersInfo()
        },
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',
            'modules_sp',
        ]),

        provider() {
            return this.current_sp ? {
                SPID:        this.current_sp.SPID,
                Name:        this.current_sp.Name        || ' ',

                LogoWideURL: this.current_sp.LogoWideURL || ' ',
                LogoIconURL: this.current_sp.LogoIconURL || ' ',

                DefaultEmailFromName:    this.current_sp.DefaultEmailFromName    || ' ',
                DefaultEmailFromAddress: this.current_sp.DefaultEmailFromAddress || ' ',
                DefaultSMSFromName:      this.current_sp.DefaultSMSFromName      || ' ',
                DefaultSMSFromNumber:    this.current_sp.DefaultSMSFromNumber    || ' ',

                Features:    [ ...this.current_sp.Features ],
            } : {
                SPID:        ' ',
                Name:        ' ',
                LogoWideURL: ' ',
                LogoIconURL: ' ',
                Features:    []
            }
        },

        with_branding() {
            return Object.keys(this.provider_branding).length > 0
        },

        is_changed_branding() {
            let is_changed = false

            for (const key in this.provider_branding) {
                if (this.isArrayChanged(this.provider_branding[ key ], this.original_branding[ key ], true)) {
                    is_changed = true
                    break
                }
            }

            return is_changed
        },

        error_message() {
            return this.errors.logos.LogoWideURL || this.errors.logos.LogoIconURL
        },
    },
}
</script>

<style lang="scss">
.page-org-settings {
    padding-bottom: 80px;

    .config {
        margin: 40px 0 0;
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-loader {
            border-radius: $border-radius-secondary;
        }

        .app-error {
            margin-bottom: 24px;
        }
        
        .board {
            margin-top: 40px;

            &:first-child {
                margin: 0;
            }

            &>h5 {
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 24px;
            }
        }

        .settings {
            .info {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 30px;

                .app-input {
                    width: calc(50% - 15px);
                    max-width: 370px;
                    margin-right: 30px;

                    &:last-child {
                        margin: 0;
                    }
                }

                .logo-preview {
                    display: flex;
                    align-items: center;

                    max-width: calc(50% - 15px);

                    height: 48px;
                    padding: 8px;

                    border-radius: $border-radius-primary;
                    border: solid 1px var(--color-input-border);

                    img {
                        display: block;

                        &.wide {
                            max-width: 100%;
                            height: auto;
                            max-height: 32px;
                            object-fit: contain;
                        }

                        &.small {
                            width: 100%;
                            max-width: 32px;

                            height: auto;
                            max-height: 32px;
                        }
                    }
                }
            }
        }

        .features {
            .app-checkbox-group {
                .options {
                    justify-content: flex-start;

                    .app-checkbox {
                        margin: 24px 48px 0 0;
                        white-space: nowrap;

                        &:last-child { margin-right: 0; }
                    }
                }
            }
        }

        .branding {
            .branding-body {
                position: relative;

                &.loading {
                    min-height: 120px;
                    border-radius: $border-radius-secondary;
                }

                .app-loader {
                    background: var(--color-table-head-bg);

                    svg {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
        }

        &>.btn {
            margin-top: 30px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-org-settings {
        .config {
            .board {
                &>h5 {
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            .settings {
                .info {
                    .app-input {
                        max-width: 258px;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-org-settings {
        .config {
            margin: 32px 0 0;
            padding: 16px 8px;

            .board {
                margin-top: 32px;
                
                &>h5 {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .settings {
                .info {
                    flex-direction: column;
                    margin: 0 0 24px 0;

                    .app-input {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 0 24px 0;

                        &:nth-child(2) {
                            margin: 0;
                        }
                    }

                    .logo-preview {
                        max-width: 100%;
                    }
                }
            }

            .features {
                .app-checkbox-group {
                    .options {
                        justify-content: space-between;

                        .app-checkbox {
                            width: 35%;
                            min-width: 120px;
                            margin: 24px 0 0;
                        }
                    }
                }
            }

            &>.btn {
                margin-top: 24px;
            }
        }
    }
}
</style>